import React, { useCallback, useMemo } from 'react'
import { PageMaxWidth } from '../util-components/util-components'
import Panel from '../panel/panel'
import { useTranslation } from 'react-i18next'
import PageHeader from '../page-header/page-header'
import AdminTable, { DataCols } from '../admin-table/admin-table'
import { navigate } from 'gatsby'
import { GET_TOURNAMENT_GROUPS } from './tournament-groups-overview-queries'
import {
  GetTournamentPools,
  GetTournamentPoolsVariables,
  GetTournamentPools_paginatedTournamentPools_items as TournamentPool
} from 'src/graphql-types/GetTournamentPools'
import { useOrgId } from 'src/apollo/local-state'
import { Category } from '../tournament-attributes/tournament-attributes'
import moment from 'moment'
import { useControlledQuery } from '../table-controls/table-controls'
import Button from '../button/button'
import * as styles from './tournament-groups-overview.module.less'
import { tournamentsClient } from 'src/apollo/client'

const TournamentGroups: React.FC = () => {
  const { t } = useTranslation()

  const orgId = useOrgId()
  const getTotalItems = useCallback(
    (d: GetTournamentPools) => d.paginatedTournamentPools.totalItems,
    []
  )

  const { data, error, loading, controlProps, refetch } = useControlledQuery<
    GetTournamentPools,
    GetTournamentPoolsVariables,
    TournamentPool
  >(GET_TOURNAMENT_GROUPS, {
    client: tournamentsClient,
    variables: { orgId },
    getTotalItems,
    notifyOnNetworkStatusChange: true
  })

  const handleRowClick = tg => {
    navigate(`/tournaments/groups/${tg.id}`)
  }

  const cols: DataCols<TournamentPool> = useMemo(() => {
    return [
      { key: 'name', title: t('group name'), getValue: g => g.name },
      {
        key: 'category',
        title: t('circuit'),
        getValue: g => <Category category={g.category} />
      },
      {
        key: 'level',
        title: t('level'),
        getValue: g => g.level.name
      },
      {
        key: 'tournamentCount',
        title: t('num tournaments'),
        getValue: g => g.tournamentCount
      },
      {
        key: 'fromDate',
        title: t('date range'),
        getValue: g =>
          t('tournament group dates', {
            dates: { start: moment(g.fromDate), end: moment(g.toDate) }
          })
      },
      {
        key: 'createdByFirstName',
        title: t('created by'),
        getValue: g => `${g.createdByFirstName ?? ''} ${g.createdByLastName ?? ''}`
      }
    ]
  }, [t, data])

  return (
    <PageMaxWidth>
      <PageHeader title={t('tournament groups')} />
      <Panel
        headerEndContent={
          <Button onClick={() => navigate('/tournaments/groups/create-tournament-group')}>
            {t('add group')}
          </Button>
        }
      >
        <AdminTable
          error={error?.message}
          controls={controlProps}
          data={data?.paginatedTournamentPools.items}
          columns={cols}
          loading={loading}
          onRowClick={tg => handleRowClick(tg)}
        />
      </Panel>
    </PageMaxWidth>
  )
}

export default TournamentGroups
